import logo from './logo.svg';
import './App.css';
import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import {Accordion, Button, FloatingLabel, Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import mixpanel from 'mixpanel-browser';
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
import app from './firebase'
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import {Line, Bar} from 'react-chartjs-2'
import {Chart as ChartJS, LineElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend, PointElement} from 'chart.js'
import Carousel from 'react-bootstrap/Carousel';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Image from "react-bootstrap/Image";



ChartJS.register(
  Tooltip,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement
)

var backgroundCol = '#222023'
var bgColor1,bgColor2,bgColor3,bgColor4,bgColor5,bgColor6,bgColor7,bgColor8,bgColor9,bgColor10,bgColor11,bgColor12,bgColor13,bgColor14,bgColor15,bgColor16,bgColor17,
    bgColor18,bgColor19,bgColor20,bgColor21,bgColor22,bgColor23,bgColor24,bgColor25,bgColor26,bgColor27,bgColor28,bgColor29,bgColor30,bgColor31,bgColor32,bgColor33,
    bgColor34,bgColor35,bgColor36,bgColor37,bgColor38,bgColor39,bgColor40,bgColor41,bgColor42,bgColor43, bgColor44,bgColor45,bgColor46,bgColor47,bgColor48,bgColor49,
    bgColor50,bgColor51, bgColor52,bgColor53,bgColor54,bgColor55,bgColor56,bgColor57,bgColor58,bgColor59,bgColor60,bgColor61,bgColor62,bgColor63,bgColor64,bgColor65,
    bgColor66,bgColor67,bgColor68,bgColor69,bgColor70,bgColor71,bgColor72,bgColor73,bgColor74,bgColor75,bgColor76,bgColor77,bgColor78,bgColor79, bgColor80

var startingPoint
var switcher= false
var last_button

var spoiler = false
var paused
var ended
let variableName
const height = 25
const height1 = 100
// var change
var arr_true_false = [];
var arr_available  = [];
var arr_available_arr = []
var emoji_available_arr = []
let positions = [];
let colors = [];
let level_heights = [];
let time_lines = [];
let buttons
let line_chart
var switched1= false
var switched2= false
var switched3= false
var switched4= false
var switched5= false
var switched6= false
var switched7= false
var switched8= false
var switched9= false
var switched10= false
var switched11= false
var switched12= false
var switched13= false
var switched14= false
var switched15= false
// let color_arr = ["#FFFF00", "#00D1FF", "#7CFC00", "#FF00D3", "#FF8700", "#A45BBB", "#778899", "#8200FF", "#FFFFFF", "#55AAAA", "#B22222", "#FF0000", "#6F861A", "#4B6ED7"]
const color_arr = []
const color_sweetness = []
var strSweetness = []
const colorAppearance = []

const db = firebase.firestore();
var lineRef = db.collection('moodlines')
var spoilerRef = db.collection('moodlines').doc()
var signature
var doc_id
const arr = []
const line_arr = []
var max_count = 0
var incrementing
// var variableNamed = 'dan'
// let arr_available_map
var pause_var


function App() {

  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(true);
  const [show3, setShow3] = useState(true);
  const [show4, setShow4] = useState(true);
  const [show5, setShow5] = useState(true);
  const [show6, setShow6] = useState(true);
  const [show7, setShow7] = useState(true);
  const [show8, setShow8] = useState(true);
  const [show9, setShow9] = useState(true);
  const [show10, setShow10] = useState(true);
  const [show11, setShow11] = useState(true);
  const [show12, setShow12] = useState(true);
  const [show13, setShow13] = useState(true);
  const [show14, setShow14] = useState(true);
  const [show15, setShow15] = useState(true);
  const [showInfo, setShowInfo] = useState(true);
  const [showChart, setChart] = useState(true);
  const [showCarosel, setShowCarosel] = useState(true);
  const [dontShow, setDontShow] = useState(false);
  const [time, setTime] = useState(true);
  const [title, setTitle] = useState(true);
  const [username, setUserName] = useState(true);
  const [uzer, setUzer] = useState('anonymous');
  const [key, setKey] = useState(true);
  var [mean, setMean] = useState('Not Available');
  var [change, setChange] = useState('change1');
  const [totalSeconds, setTotalSecs] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [searchText, setSearchText] = useState("");
  const [hints, setHints] = useState([]);
  const [filteredHints, setFilteredHints] = useState([]);
  const [selectedHint, setSelectedHint]=useState(null);
  var [id, setID]=useState([]);
  // var [pause_var, setPauseVar]=useState('II');
  mixpanel.init("0587313f16b659ae33e572f9049cbe5d", {
  });
  const switch_arr = ['switched1', 'switched2', 'switched3', 'switched4', 'switched5', 'switched6', 'switched7', 'switched8', 'switched9', 'switched10', 'switched11', 'switched12', 'switched13', 'switched14', 'switched15']
  
  // var time_lines_test = ['00:00:00', '', '', '', '', '', '', '','', '00:40:09']
  // var level_height = [3, 4, 4, 2, 1, 4, 5, 4, 3, 2]

  useEffect(() => {
    // Listen for changes in the Firestore collection
    // if (searchText != ""){
    const unsubscribe = db.collection("moodlines").onSnapshot((snapshot) => {
      const newHints = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      // console.log('newHints:', newHints)
      setHints(newHints);
    });

    // Clean up listener
    return () => unsubscribe();
  // }
  }, []);


  const handleSearchTextChange = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    // console.log('searchText:', searchText)

    if(searchText.length < 2){
      setFilteredHints([])
      return;
    } else {
        // Filter hints based on search text
        const filteredHints = hints.filter((hint) =>{
          // console.log('hint:', hint)
        if (hint !== null) {
          return hint.title.toLowerCase().includes(newSearchText.toLowerCase());
        }
        else{
          return [];
        }
        });
        setFilteredHints(filteredHints);
        // console.log('filteredHints:', filteredHints)
    }

  };



  function findGroups(){
    // console.log(key)
    if(key==true){
      alert("Please input the key to the group you'll like to join")
      return
    }
    lineRef.where("key", "==", key)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        alert('Sorry this Keyword doesnt exist')
      }else{
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //  console.log(doc.id, " => ", doc.data());
          // get startPoint
          var startPoint = doc.data().startPoint
          startingPoint = startPoint
          var change = 'change' + startingPoint
          setChange(change)
          // console.log('change: ', change)
        //   // get timeLength in seconds
          var timeLength = doc.data().timeLength
          setTotalSecs(timeLength)
          // console.log("buttons: ", doc.data().button)
          buttons = doc.data().button
  
          buttons.forEach((str) => {
            var [position, color] = str.split('-');
            positions.push(position);
            colors.push(color);
          });

          // console.log('positions: ', positions); // Output: ['1', '2', '3', '4']
          // console.log('colors: ', colors);
          // TODO: for bars that have same position, chose the color with highest
          // else don't show that bar
         draw(positions, colors)
         setShow(false)

        });

      }
       
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
        return
    });
    
  }

  function findGroup(){
    // console.log(key)
    if(key==true){
      alert("Please input the key to the group you'll like to join")
      return
    }
    lineRef.where("key", "==", key)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        alert('Sorry this Keyword doesnt exist')
      }else{
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //  console.log(doc.id, " => ", doc.data());
          // get startPoint
          var startPoint = doc.data().startPoint
          startingPoint = startPoint
          var change = 'change' + startingPoint
          setChange(change)
          // console.log('change: ', change)
          // get timeLength in seconds
          var timeLength = doc.data().timeLength
          setTotalSecs(timeLength)
          // console.log("buttons: ", doc.data().button)
          let buttons = doc.data().button
          const positions = [];
          const colors = [];

          buttons.forEach((str) => {
            const [position, color] = str.split('-');
            positions.push(position);
            colors.push(color);
          });

          // console.log(positions); // Output: ['1', '2', '3', '4']
          // console.log(colors);

         draw(positions, colors)
         setShow(false)

        });

      }
       
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
        return
    });
    
  }

  function countAllOccurrences(array) {
    var duplicates = [];
    array.forEach((item, index) => {
      if (array.indexOf(item, index + 1) !== -1 && duplicates.indexOf(item) === -1) {
        duplicates.push(item);
      }
    });
    return duplicates
  }

  function searchForIndexes(array, itemsToSearch){
    // const array = [5, 10, 15, 20, 25, 30];
    // const itemsToSearch = [15, 25];
    const indexes = [];
    itemsToSearch.forEach(item => {
      let index = array.indexOf(item);
      while (index !== -1) {
        indexes.push(item);
        index = array.indexOf(item, index + 1);
      }
    });

  return indexes
  }

  function searchForIndexes2(array, itemsToSearch){
    // const array = [5, 10, 15, 20, 25, 30];
    // const itemsToSearch = [15, 25];
    const items = [];
    itemsToSearch.forEach(item => {
      let index = array.indexOf(item);
      while (index !== -1) {
        items.push(index);
        index = array.indexOf(item, index + 1);
      }
    });

  return items
  }

// arrange colors in array for each duplicate for counting
  function getColorsForEachDuplicate(duplicates, myArray, otherArray){
    const result = duplicates.map(duplicate => {
      const indexes = myArray.reduce((acc, item, index) => {
        if (item === duplicate) {
          acc.push(index);
        }
        return acc;
      }, []);
      return indexes.map(index => otherArray[index]);
    });
    return result
  }


  function getMostCommonColor(myArray){
    const result = myArray.map(subArray => {
      const countMap = subArray.reduce((map, item) => {
        map[item] = (map[item] || 0) + 1;
        return map;
      }, {});
      
      const maxCount = Math.max(...Object.values(countMap));
      const maxItem = Object.keys(countMap).find(key => countMap[key] === maxCount);
      
      return maxCount === 1 ? '#000000' : maxItem;
    });
    return result
  }

  function draw(positions, colors){
    // let positionsArr = positions.concat(positions);
    // console.log('positionsArr: ', positionsArr)
    var duplicates= []
    duplicates = countAllOccurrences(positions);
    // console.log('duplicates: ', duplicates)
    if (duplicates === undefined || duplicates.length == 0) {
      // array does not exist or is empty
      colors.map((color, id) => 
      // console.log("positions: ", positions[id], color)
      document.getElementById('change' + positions[id]).style.color = color
      )
      // return
  } else {
        // keep record of how many times a position is called
            // if a position is called more than once
            // keep record of the colors on that position
            var indexes = searchForIndexes(positions, duplicates)
            // console.log('indexes: ', indexes)
            var items = searchForIndexes2(positions, duplicates)
            // console.log('items: ', items)
            const colorArr = items.map(index => colors[index]);
            // console.log('colors in array :', colorArr)
            const getColors = getColorsForEachDuplicate(duplicates, indexes, colorArr)
            // console.log('getColors :', getColors)
            // if the colors are same, use the color
            // if the colors are different, get the color with the highest occurence and use
            //  if the occur same number of times use black
            const mostCommonColors = getMostCommonColor(getColors)
            // console.log('mostCommonColors :', mostCommonColors)

            colors.map((color, id) => 
              // console.log("positions: ", positions[id], color)
            document.getElementById('change' + positions[id]).style.color = color
            )

            mostCommonColors.map((color, id) => 
              // console.log("positions: ", positions[id], color)
            document.getElementById('change' + duplicates[id]).style.color = color
            )

  }
    
    // TODO: black when you click twice shouldn't be


  }


  function startPainting(){

    if(time == true || username == true || title == true){
      alert('please, fill all entries')
      return
    }

    startingPoint = 1
    setChange('change1')
    setDontShow(true)
    setShowCarosel(false)
    // change = 'change1'

    // if(username == true){
    //   alert('please input time length')
    //   return
    // }
    const timeArray = time.split(':')
    const hours = parseInt(timeArray[0], 10); // parse hours as an integer
    const minutes = parseInt(timeArray[1], 10); // parse minutes as an integer
    const seconds = parseInt(timeArray[2], 10); // parse seconds as an integer
    const totalSeconds = hours * 3600 + minutes * 60 + seconds; // calculate total number of seconds
    // console.log(totalSeconds)
    signature = title + totalSeconds + username
    signature = signature.replaceAll(/\s/g,'')
    signature = signature.replaceAll(' ','')
    var uploadedKey = title + ' ('+ time +')'
    // uploadedKey = uploadedKey.replaceAll(/\s/g,'')
    // uploadedKey = uploadedKey.replaceAll(' ','')

    var docData = {
      signature: signature, 
      title: title,
      timeLength: totalSeconds,
      userName: username,
      startPoint: 1,
      key: uploadedKey,
      timeLengthFull: time,
      button: [],
      buttonName: []
    }

        spoilerRef.set(docData).then(() => {
          // console.log('docId: ' + docData.documentID);
          // console.log(docData);
      })

    setTotalSecs(totalSeconds);
    mixpanel.track('start painting', {
      time: new Date().toLocaleString(),
      totalSeconds: totalSeconds,
      userName: username,
      title: title,
      timeLength: time
    })
    setShow(false)

    db.collection('moodlines').where('signature', "==", signature)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        alert('There seem to be an error, Pls refresh')
      }else{

        const snapshots = snapshot.docs.map((doc) => ({
          id: doc.id,
          // ...doc.data()
        }));
          // find the user with userID
        //   lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
        //     button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
        //     // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
        // }, { merge: true })
        // console.log('id: ', snapshots[0].id)
        doc_id = snapshots[0].id
        
      }
     });

  }

  document.body.style = 'background: black;';
  var i = startingPoint
  var i_last
  // var endTime = 5;
  // var endSec = endTime * 60
  var oneKpa = totalSeconds/77
  var oneKpaMiliSec = oneKpa * 1000
  // var change = 'change1'
  // console.log(oneKpa)
  // change variable changes after every kpa
  const interval = setInterval(() => {
    
    if(paused == true){
      i_last = i
      // clearInterval(interval)
      // console.log('i: '+ i, 'i_last: ' + i_last)
    }else{
      i++; // increment the counter by 1 after every 10 seconds
      incrementing = true;
      change = 'change'+i
      // console.log('i: '+ i, 'i_last: ' + i_last)
          // console.log('i: ', i.toString()); // display the current value of the counter in the console
            if (i == 77  && showCarosel === false){
              db.collection('moodlines').doc(doc_id).update({
              button: arr,
              line_chart: line_arr,
              buttonName: color_arr
              // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
          })
          i=0
          clearInterval(interval)
          incrementing = false;
          ended = true
          alert('Great! Your SpoilerGraph has been successfully uploaded!')
          return
          // drop()
        }

        if(spoiler==true){   
        let index 
        if (positions.includes(i.toString())){
            index = positions.indexOf(i.toString());
            console.log(i, positions[index], colors[index] )
            document.getElementById('change' + positions[index]).style.color = colors[index]
          }

        // let the buttons disappear leaving only the current button
        // if(color_arr.includes(colors[index])){
        //   const index_color = color_arr.indexOf(colors[index])
        //   switcher = false
        //   console.log('index_color: ', index_color)
        //   for (let k = 0; k < switch_arr.length; k++) {
        //     if (k !== index_color) {
        //       switch_arr[k] = false;
        //     }else{
        //       switch_arr[k] = true;
        //     }

        //     console.log('switched1: ', switched1)
        //     console.log('switched2: ', switched2)
        //     console.log('switched3: ', switched3)
        //   }
        //   // drop()
        //   console.log(switch_arr, colors[index] )
        //   // console.log(switch_arr, colors[index])
        // }

        }

            }

  }, oneKpaMiliSec); // set the interval to 10 seconds (10000 milliseconds)


  function pause1() {
    if (incrementing == true) {
      clearInterval(interval);
      incrementing = false;
      console.log('i: '+ i, incrementing)
    } else {
      incrementing = true;
      setInterval()
      console.log('i: '+ i, incrementing)
    }
  }

  if (paused == true) {
    pause_var = 'continue'
  } else {
    pause_var = 'pause'
  }

  const pausebutton = document.getElementById("pauseButton");
  
  function pause() {
    if (paused == true) {
      paused = false
      pausebutton.textContent = "pause";
       setInterval()
    } else {
      paused = true
      pause_var = 'continue'
      pausebutton.textContent = "continue";
    }
  }


function changeColor1(){
  // var index = 0
  // var index = 1 + index
  // console.log('change: ', change)
  if(paused == true){
    return
  }

  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#FFFF00"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  // document.getElementById(change+'a').style.color = "#FFFF00"
  switched1 = true
  var color = "FFFF00"
  last_button = "#FFFF00"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched1')
  // lineRef.where('signature', "==", signature)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
        // find the user with userID
      //   db.collection('moodlines').doc(doc_id).update({
      //     button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
      //     // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
      // }, { merge: true })

    // }
  //  });
  // console.log('colorDiff: ', colorDiff)
  mixpanel.track('color1', {
    time: new Date().toLocaleString()
  })
}

function changeColor2(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#00D1FF"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched2 = true
  var color = "00D1FF"
  last_button = "#00D1FF"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
        // find the user with userID
        arr.push(colorDiff)
        color_arr.push('switched2')
      //   db.collection('moodlines').doc(doc_id).update({
      //     button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
      //     // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
      // }, { merge: true })

  //   }
  //  });
  // console.log('colorDiff: ', colorDiff)
  mixpanel.track('color2', {
    time: new Date().toLocaleString()
  })
}

function changeColor3(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#7CFC00"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched3 = true
  var color = "7CFC00"
  last_button = "#7CFC00"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched3')
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
  //       // find the user with userID
  //       lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
  //         button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
  //         // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
  //     }, { merge: true })

  //   }
  //  });
  mixpanel.track('color3', {
    time: new Date().toLocaleString()
  })
}

function changeColor4(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#FF00D3"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched4 = true
  var color = "FF00D3"
  last_button = "#FF00D3"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched4')
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
  //       // find the user with userID
  //       lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
  //         button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
  //         // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
  //     }, { merge: true })

  //   }
  //  });
  mixpanel.track('color4', {
    time: new Date().toLocaleString()
  })
}

function changeColor5(){
  if(paused == true){
    return
  }
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#FF8700"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched5 = true
  var color = "FF8700"
  last_button = "#FF8700"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched5')
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
  //       // find the user with userID
  //       lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
  //         button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
  //         // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
  //     }, { merge: true })

  //   }
  //  });
  mixpanel.track('color5', {
    time: new Date().toLocaleString()
  })
}

function changeColor6(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#A45BBB"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched6 = true
  var color = "A45BBB"
  last_button = "#A45BBB"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched6')
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
  //       // find the user with userID
  //       lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
  //         button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
  //         // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
  //     }, { merge: true })

  //   }
  //  });
  mixpanel.track('color6', {
    time: new Date().toLocaleString()
  })
}

function changeColor7(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#778899"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched7 = true
  var color = "778899"
  last_button = "#778899"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched7')
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
  //       // find the user with userID
  //       lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
  //         button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
  //         // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
  //     }, { merge: true })

  //   }
  //  });
  mixpanel.track('color7', {
    time: new Date().toLocaleString()
  })
}

function changeColor8(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#8200FF"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched8 = true
  var color = "8200FF"
  last_button = "#8200FF"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched8')
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
  //       // find the user with userID
  //       lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
  //         button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
  //         // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
  //     }, { merge: true })

  //   }
  //  });
  mixpanel.track('color8', {
    time: new Date().toLocaleString()
  })
}

function changeColor9(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#FFFFFF"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched9 = true
  var color = "FFFFFF"
  last_button = "#FFFFFF"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched9')
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
  //       // find the user with userID
  //       lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
  //         button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
  //         // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
  //     }, { merge: true })

  //   }
  //  });
  mixpanel.track('color9', {
    time: new Date().toLocaleString()
  })
}

function changeColor10(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#55AAAA"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched10 = true
  var color = "55AAAA"
  last_button = "#55AAAA"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched10')
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
  //       // find the user with userID
  //       lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
  //         button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
  //         // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
  //     }, { merge: true })

  //   }
  //  });
  mixpanel.track('color10', {
    time: new Date().toLocaleString()
  })
}

function changeColor11(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#B22222"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched11 = true
  var color = "B22222"
  last_button = "#B22222"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched11')
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
  //       // find the user with userID
  //       lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
  //         button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
  //         // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
  //     }, { merge: true })

  //   }
  //  });
  mixpanel.track('color11', {
    time: new Date().toLocaleString()
  })
}

function changeColor12(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#FF0000"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched12 = true
  var color = "FF0000"
  last_button = "#FF0000"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched12')
  // lineRef.where('key', "==", key)
  // .get()
  // .then((snapshot) => {
  //   if (snapshot.empty) {
  //     alert('There seem to be an error, Pls refresh')
  //   }else{
  //       // find the user with userID
  //       lineRef.doc('GXp5W0dQqsa5d9yzXiW6').update({
  //         button: firebase.firestore.FieldValue.arrayUnion(colorDiff),
  //         // position: firebase.firestore.FieldValue.arrayUnion(changeNum),
  //     }, { merge: true })

  //   }
  //  });
  mixpanel.track('color12', {
    time: new Date().toLocaleString()
  })
}

function changeColor13(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#6F861A"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched13 = true
  var color = "6F861A"
  last_button = "#6F861A"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched13')
  mixpanel.track('color13', {
    time: new Date().toLocaleString()
  })
}


function changeColor14(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#E47070"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched14 = true
  var color = "E47070"
  last_button = "#E47070"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched14')
  mixpanel.track('color14', {
    time: new Date().toLocaleString()
  })
}


function changeColor15(){
  if(paused == true){
    return
  }
  
  if(ended == true){
    return
  }
  document.getElementById(change).style.color = "#4B6ED7"
  // document.getElementById(change+'a').style.color = backgroundCol
  // document.getElementById(change+'b').style.color = backgroundCol
  switched15 = true
  var color = "4B6ED7"
  last_button = "#4B6ED7s"
  var changeNum = change.replace('change', '')
  var colorDiff = changeNum + '-#' + color
  arr.push(colorDiff)
  color_arr.push('switched15')
  mixpanel.track('color15', {
    time: new Date().toLocaleString()
  })
}


function getTitle(title){
  setTitle(title.target.value)
}

function getUserName(username){
  setUserName(username.target.value)
}

function drop(){
  if (switcher == false){
    switcher = true
  }else{
    switcher = false
  }
  // console.log(switched1)
  // console.log('switcher', switcher)

  if(switched1 == false && switcher == true){
  setShow1(false)
  }else{
    setShow1(true)
  }

  if(switched2 == false && switcher == true){
    setShow2(false)
    }else{
      setShow2(true)
    }

  if(switched3 == false && switcher == true){
    setShow3(false)
    }else{
      setShow3(true)
    }

  if(switched4 == false && switcher == true){
    setShow4(false)
    }else{
      setShow4(true)
    }

  if(switched5 == false && switcher == true){
    setShow5(false)
    }else{
      setShow5(true)
    }

  if(switched6 == false && switcher == true){
    setShow6(false)
    }else{
      setShow6(true)
    }

  if(switched7 == false && switcher == true){
    setShow7(false)
    }else{
      setShow7(true)
    }
              
              
  if(switched8 == false && switcher == true){
    setShow8(false)
    }else{
      setShow8(true)
    }


  if(switched9 == false && switcher == true){
    setShow9(false)
    }else{
      setShow9(true)
    }

  if(switched10 == false && switcher == true){
    setShow10(false)
    }else{
      setShow10(true)
    }


  if(switched11 == false && switcher == true){
    setShow11(false)
    }else{
      setShow11(true)
    }


  if(switched12 == false && switcher == true){
    setShow12(false)
    }else{
      setShow12(true)
    }

  if(switched13 == false && switcher == true){
    setShow13(false)
    }else{
      setShow13(true)
    }

    if(switched14 == false && switcher == true){
      setShow14(false)
      }else{
        setShow14(true)
      }


      if(switched15 == false && switcher == true){
        setShow15(false)
        }else{
          setShow15(true)
        }
    
    }


    function getSelectedHint(hintOption)
    {
      // console.log('hintOption: ', hintOption.target.outerText)
      // setSelectedHint(hintOption.target.outerText)
      setSearchText(hintOption.target.outerText)
      setFilteredHints([])
      // searchHints()
    }

    function searchHints(){
      // console.log('id:', id)
      // use the id to get the doc, then 
      // get the time it takes
      // 
      // spoiler = true
      setDontShow(false)
      setShowCarosel(true)
      startingPoint = 1
      setChange('change1')
      setTotalSecs(id.timeLength);
      mixpanel.track('Playing a Spoiler', {
        time: new Date().toLocaleString(),
        totalSeconds: totalSeconds,
        searchText: searchText
      })
          buttons = id.button
          if (buttons == undefined){
            alert('Sorry, we do not have the graph for this movie at the moment, try another')
            return
          }
  
          buttons.forEach((str) => {
            var [position, color] = str.split('-');
            positions.push(position);
            colors.push(color);
          });

          line_chart = id.line_chart
          if (line_chart == undefined){
            level_heights.push(0,0,0,0);
            time_lines.push(0,0,0,0);
          }else{
          // console.log(line_chart)
          line_chart.forEach((str) => {
            var [level_height, time_line] = str.split('-');
            level_heights.push(level_height);
            time_lines.push(time_line);
          });
          }
          setUzer(id.userName)
      // console.log('positions: ', positions)
      // for not live
      if (spoiler == false){
        draw(positions, colors)
        // setDontShow(false)
        // setShowCarosel(true)
        
      } 
      
        // loop through variables in array1 and check if they exist in array2
      switch_arr.forEach(function(variableName) {
        if (id.buttonName.indexOf(variableName) > -1) {
          // console.log('vn: ', variableName)
          arr_true_false.push(true)
          // push into this array so we can know which colors are present
          // and use it in the graph label
          arr_available.push(variableName)
        }else{
          arr_true_false.push(false)
        }
      });

      calculateMean(level_heights)
      setTrueorFalse(arr_true_false)
      setTime(id.timeLengthFull)
      setTitle(id.title)
      getColorNumbers()
      setShow(false)

    }

    function setTrueorFalse(arr){
        switched1= arr[0]
        switched2= arr[1]
        switched3= arr[2]
        switched4= arr[3]
        switched5= arr[4]
        switched6= arr[5]
        switched7= arr[6]
        switched8= arr[7]
        switched9= arr[8]
        switched10= arr[9]
        switched11= arr[10]
        switched12= arr[11]
        switched13= arr[12]
        switched14= arr[13]
        switched15= arr[14]
        drop()
    }

    function liveSwitch(){
      if (spoiler == true){
        spoiler = false
      }else{
        spoiler = true
      }
    }

    // function getColorNumbers(){
    //   const color_scores = {"#FFFF00" :8, "#00D1FF":7, "#7CFC00": 6, "#FF00D3":10, "#FF8700":7, "#A45BBB":9, "#778899":2, "#8200FF":9, "#FFFFFF":5, "#55AAAA":9, "#B22222":7, "#FF0000":9, "#6F861A":7}
    //   for (let i =0; i < colors.length; i++){
    //     const color = colors[i];
    //     const sweetness = color_scores[color]; // Get sweetness score from dictionary
    //     color_sweetness.push(sweetness);
    //     // sweetnessArray.push(sweetness);
    //       }

    //       strSweetness = color_sweetness.map(String);
    //       for (let i = 0; i < strSweetness.length - 1; i++) {
    //           strSweetness[i] = "";
    //         }
    //         strSweetness[strSweetness.length - 1] = id.timeLengthFull;
    // }


// get all the colors that appear in colors
// let color_appear = ["#FFFF00", "#00D1FF", "#7CFC00", "#FF00D3", "#FF8700", "#A45BBB", "#778899", "#8200FF", "#FFFFFF", "#55AAAA", "#B22222", "#FF0000", "#6F861A"]
// console.log('arr_available: ', arr_available)

function getColorNumbers(){

  const arr_available_map = new Map([['switched14', '#E47070'], ['switched9', '#FFFFFF'],['switched12', '#FF0000'],['switched11', '#B22222'],['switched8', '#8200FF'],
  ['switched10', '#55AAAA'],['switched7', '#778899'],['switched6', '#A45BBB'],['switched2', '#00D1FF'],['switched4', '#FF00D3'],['switched13', '#6F861A'],['switched5', '#FF8700'],['switched1', '#FFFF00'], ['switched3', '#7CFC00']])
  const emoji_available_map = new Map([['#FFFFFF', '✍️'],['#FF0000', '😡'],['#B22222', '☹️'],['#8200FF', '😱'],
  ['#55AAAA', '😭'],['#778899', '🥱'],['#A45BBB', '😮'],['#00D1FF', '🤩'],['#FF00D3', '🤯'],['#6F861A', '👎'],['#7CFC00', '👍'],['#FFFF00', '😂'], ['#FF8700', '😍'], ['#E47070', '🔞']])
  // arr_available_arr = arr_available.map(col => arr_available_map.get(col));
  arr_available.forEach(item => {
    const value = arr_available_map.get(item);
    arr_available_arr.push(value);
  });

  arr_available_arr.forEach(item => {
    const value = emoji_available_map.get(item);
    emoji_available_arr.push(value);
  });

  console.log('arr_available_arr: ', arr_available_arr)
  console.log('emoji_available_arr: ', emoji_available_arr)



  // get the number of time each color appear in colors
    for (let i = 0; i < arr_available_arr.length; i++) {
      const item = arr_available_arr[i];
      const count = colors.filter(x => x === item).length;
      if(count > max_count){
        max_count = count
      }
      colorAppearance.push(count);
    }
// console.log(max_count)
}



const data_line = {
  labels: time_lines,
  datasets: [{
    label: ' How Engaging?',
    data: level_heights,
    backgroundColor: 'transparent',
    borderColor: '#00D1FF',
    borderWidth:2,
  }]
};
const options_line = {
  plugins: {
    legend: false
  },
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        //   font: {
        //     size: 20,
        // },      
      },
    },
    y: {
      min:0,
      max: 5,
      // title: {
      //   display: true,
      //   text: ' How ' + ' ' + ' Engaging ' + '  ?',
      // },
      ticks: {
        stepSize:1,
        callback: (value) => value + ' ⭐'
      },
      grid: {
        borderDash: [10]
      }
    }
  }
}

// ChartJS.defaults.font.size = 16;
    const data = {
      labels: emoji_available_arr,
      datasets: [{
        label: ' num of scenes',
        data: colorAppearance,
        backgroundColor: 'transparent',
        borderColor: '#00D1FF',
        borderWidth:2,
      }]
    };
    const options = {
      plugins: {
        legend: false
      },
      responsive: true,
      scales: {
        x: {
          grid: {
            display: false
          },
          ticks: {
              font: {
                size: 20,
            },      
          },
        },
        y: {
          min:0,
          max: max_count,
          title: {
            display: true,
            text: "Number  of  Scenes  ?"
          },
          ticks: {
            stepSize:2,
            callback: (value) => value
          },
          grid: {
            borderDash: [10]
          }
        }
      }
    }

    function secondsToHms(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60 );
    
      const hh = hours.toString().padStart(2, '0');
      const mm = minutes.toString().padStart(2, '0');
      const ss = remainingSeconds.toString().padStart(2, '0');
    
      return `${hh}:${mm}:${ss}`;
    }

    function calculateMean(numbers) {

      const convertedNumbers = numbers.map(Number); // convert strings to numbers
      const sum = convertedNumbers.reduce((acc, curr) => acc + curr, 0); // calculate the sum of all numbers
      const mean = sum / convertedNumbers.length; // calculate the mean
      const meanWithOneDecimal = mean.toFixed(1);
      if(meanWithOneDecimal == 0){
        setMean('Not Available')
        return
      }else{
        setMean(Number(meanWithOneDecimal) + "/5")
      }
      // return mean;
    }
  
    function times1(){
      // get last button pressed
      //   document.getElementById(change).style.color = last_button 
      // document.getElementById(change+'a').style.color = last_button
      if(paused == true){
        return
      }
      
      if(ended == true){
        return
      }

      // add current time with level
      var secs_per_line = oneKpa * i
      var secondsToHms_ret = secondsToHms(secs_per_line)
      var time_lev = "1-" + secondsToHms_ret 
      line_arr.push(time_lev)
      mixpanel.track('times1', {
        time: new Date().toLocaleString()
      })

    }

    function times2(){
      // get last button pressed
      //   document.getElementById(change).style.color = last_button 
      // document.getElementById(change+'a').style.color = last_button

      if(paused == true){
        return
      }
      
      if(ended == true){
        return
      }

      // add current time with level
      var secs_per_line = oneKpa * i
      var secondsToHms_ret = secondsToHms(secs_per_line)
      var time_lev = "2-" + secondsToHms_ret 
      line_arr.push(time_lev)
      mixpanel.track('times2', {
        time: new Date().toLocaleString()
      })
    }

    function times3(){
      // document.getElementById(change).style.color = last_button 
      // document.getElementById(change+'a').style.color = last_button
      // document.getElementById(change+'b').style.color = last_button
      if(paused == true){
        return
      }
      
      if(ended == true){
        return
      }

      // add current time with level
      var secs_per_line = oneKpa * i
      var secondsToHms_ret = secondsToHms(secs_per_line)
      var time_lev = "3-" + secondsToHms_ret 
      line_arr.push(time_lev)
      mixpanel.track('times3', {
        time: new Date().toLocaleString()
      })
    }

    function times4(){
      // document.getElementById(change+'a').style.color = last_button
      // document.getElementById(change+'b').style.color = last_button
      // document.getElementById(change+'c').style.color = last_button 
      // document.getElementById(change).style.color = last_button 
      if(paused == true){
        return
      }
      
      if(ended == true){
        return
      }

      // add current time with level
      var secs_per_line = oneKpa * i
      var secondsToHms_ret = secondsToHms(secs_per_line)
      var time_lev = "4-" + secondsToHms_ret 
      line_arr.push(time_lev)
      mixpanel.track('times4', {
        time: new Date().toLocaleString()
      })
    }

    function times5(){
      // document.getElementById(change+'a').style.color = last_button
      // document.getElementById(change+'b').style.color = last_button
      // document.getElementById(change+'c').style.color = last_button 
      // document.getElementById(change).style.color = last_button 
      if(paused == true){
        return
      }
      
      if(ended == true){
        return
      }

      // add current time with level
      var secs_per_line = oneKpa * i
      var secondsToHms_ret = secondsToHms(secs_per_line)
      var time_lev = "5-" + secondsToHms_ret 
      line_arr.push(time_lev)
      mixpanel.track('times5', {
        time: new Date().toLocaleString()
      })
    }




  return (
    <div className="App">
    <div className="container">
      <nav class="navbar navbar-expand navbar-dark">
        <a href="https://www.spoilergraph.com" class="navbar-brand"> SpoilerGraph </a>
        <button
          class="navbar-toggler"
          type ="button"
          data-bs-toggle="collapse"
          data-bs-target="#toggleMobileMenu"
          aria-controls="toggleMobileMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
          >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          </ul>
        </div>
 
        {/* <span class="navbar-text"> */}
        {/* <Form style={{ borderColor: 'red'}}>
          <Form.Check 
            type="switch"
            id="custom-switch"
            onChange={drop}
            // label="Check this switch"
          />
        </Form> */}
            {/* <a href="whatsapp://send?text= Kiki, Sophie and May are in special class, help them convert their School Work to Music! https://ClassChoir.com. 🥺🙏" data-action="share/whatsapp/share"><img src="https://www.freeiconspng.com/uploads/logo-whatsapp-png-free-vector-download-7.png" width="25" alt="Logo Whatsapp Png Free Vector Download" onClick={'sos'}/> </a> */}
        {/* </span> */}
      </nav>
    </div>

        {/* <>
          <FloatingLabel style={{ margin: 20, borderRadius: 0}}
                onChange={'(e) => setEmail(e.target.value)'}
                controlId="floatingInput"
                label="Video Title?"
                className="mb-3"
              >
                <Form.Control type="name" placeholder="Video Title?" />
          </FloatingLabel>
        </> */}
    
          {/* <FloatingLabel style={{ margin: 20, borderRadius: 0}}
                onChange={'(e) => setEmail(e.target.value)'}
                controlId="floatingInput"
                label="hh:mm:ss"
                className="mb-3"
              >
                <Form.Control type="stopwatch" placeholder="hh:mm" />
          </FloatingLabel>   */}

          {dontShow &&
          <div>
          <div>
          <div style={{marginTop:20, marginLeft:20, textAlign: "left"}}><b class="mb-1"> </b>  <small class="text-muted">  </small></div>
          <div style={{marginLeft:20, textAlign: "center"}}><b class="mb-1"> </b>  <small class="text-muted"> {title} </small></div>
        <div style={{marginTop:10}}>
   
            {/* <div style={{ fontSize:15, width: 50, color:"#FFFFFF", backgroundColor:"#000000"}}  className="vr"> 4🔥 </div> */}
            {/* <div id='change1c' style={{height: height, width: 5, backgroundColor: bgColor1}} className="vr"></div>
            <div id='change2c' style={{height: height, width: 5, backgroundColor: bgColor2}} className="vr"></div>
            <div id='change3c' style={{height: height, width: 5, backgroundColor: bgColor3}} className="vr"></div>
            <div id='change4c' style={{height: height, width: 5, backgroundColor: bgColor4}} className="vr"></div>
            <div id='change5c' style={{height: height, width: 5, backgroundColor: bgColor5}} className="vr"></div>
            <div id='change6c' style={{height: height, width: 5, backgroundColor: bgColor6}} className="vr"></div>
            <div id='change7c' style={{height: height, width: 5, backgroundColor: bgColor7}} className="vr"></div>
            <div id='change8c' style={{height: height, width: 5, backgroundColor: bgColor8}} className="vr"></div>
            <div id='change9c' style={{height: height, width: 5, backgroundColor: bgColor9}} className="vr"></div>
            <div id='change10c' style={{height: height, width: 5, backgroundColor: bgColor10}} className="vr"></div>
            <div id='change11c' style={{height: height, width: 5, backgroundColor: bgColor11}} className="vr"></div>
            <div id='change12c' style={{height: height, width: 5, backgroundColor: bgColor12}} className="vr"></div>
            <div id='change13c' style={{height: height, width: 5, backgroundColor: bgColor13}} className="vr"></div>
            <div id='change14c' style={{height: height, width: 5, backgroundColor: bgColor14}} className="vr"></div>
            <div id='change15c' style={{height: height, width: 5, backgroundColor: bgColor15}} className="vr"></div>
            <div id='change16c' style={{height: height, width: 5, backgroundColor: bgColor16}} className="vr"></div>
            <div id='change17c' style={{height: height, width: 5, backgroundColor: bgColor17}} className="vr"></div>
            <div id='change18c' style={{height: height, width: 5, backgroundColor: bgColor18}} className="vr"></div>
            <div id='change19c' style={{height: height, width: 5, backgroundColor: bgColor19}} className="vr"></div>
            <div id='change20c' style={{height: height, width: 5, backgroundColor: bgColor20}} className="vr"></div>
            <div id='change21c' style={{height: height, width: 5, backgroundColor: bgColor21}} className="vr"></div>
            <div id='change22c' style={{height: height, width: 5, backgroundColor: bgColor22}} className="vr"></div>
            <div id='change23c' style={{height: height, width: 5, backgroundColor: bgColor23}} className="vr"></div>
            <div id='change24c' style={{height: height, width: 5, backgroundColor: bgColor24}} className="vr"></div>
            <div id='change25c' style={{height: height, width: 5, backgroundColor: bgColor25}} className="vr"></div>
            <div id='change26c' style={{height: height, width: 5, backgroundColor: bgColor26}} className="vr"></div>
            <div id='change27c' style={{height: height, width: 5, backgroundColor: bgColor27}} className="vr"></div>
            <div id='change28c' style={{height: height, width: 5, backgroundColor: bgColor28}} className="vr"></div>
            <div id='change29c' style={{height: height, width: 5, backgroundColor: bgColor29}} className="vr"></div>
            <div id='change30c' style={{height: height, width: 5, backgroundColor: bgColor30}} className="vr"></div>
            <div id='change31c' style={{height: height, width: 5, backgroundColor: bgColor31}} className="vr"></div>
            <div id='change32c' style={{height: height, width: 5, backgroundColor: bgColor32}} className="vr"></div>
            <div id='change33c' style={{height: height, width: 5, backgroundColor: bgColor33}} className="vr"></div>
            <div id='change34c' style={{height: height, width: 5, backgroundColor: bgColor34}} className="vr"></div>
            <div id='change35c' style={{height: height, width: 5, backgroundColor: bgColor35}} className="vr"></div>
            <div id='change36c' style={{height: height, width: 5, backgroundColor: bgColor36}} className="vr"></div>
            <div id='change37c' style={{height: height, width: 5, backgroundColor: bgColor37}} className="vr"></div>
            <div id='change38c' style={{height: height, width: 5, backgroundColor: bgColor38}} className="vr"></div>
            <div id='change39c' style={{height: height, width: 5, backgroundColor: bgColor39}} className="vr"></div>
            <div id='change40c' style={{height: height, width: 5, backgroundColor: bgColor40}} className="vr"></div>
            <div id='change41c' style={{height: height, width: 5, backgroundColor: bgColor41}} className="vr"></div>
            <div id='change42c' style={{height: height, width: 5, backgroundColor: bgColor42}} className="vr"></div>
            <div id='change43c' style={{height: height, width: 5, backgroundColor: bgColor43}} className="vr"></div>
            <div id='change44c' style={{height: height, width: 5, backgroundColor: bgColor44}} className="vr"></div>
            <div id='change45c' style={{height: height, width: 5, backgroundColor: bgColor45}} className="vr"></div>
            <div id='change46c' style={{height: height, width: 5, backgroundColor: bgColor46}} className="vr"></div>
            <div id='change47c' style={{height: height, width: 5, backgroundColor: bgColor47}} className="vr"></div>
            <div id='change48c' style={{height: height, width: 5, backgroundColor: bgColor48}} className="vr"></div>
            <div id='change49c' style={{height: height, width: 5, backgroundColor: bgColor49}} className="vr"></div>
            <div id='change50c' style={{height: height, width: 5, backgroundColor: bgColor50}} className="vr"></div>
            <div id='change51c' style={{height: height, width: 5, backgroundColor: bgColor51}} className="vr"></div>
            <div id='change52a' style={{height: height, width: 5, backgroundColor: bgColor52}} className="vr"></div>
            <div id='change53c' style={{height: height, width: 5, backgroundColor: bgColor53}} className="vr"></div>
            <div id='change54c' style={{height: height, width: 5, backgroundColor: bgColor54}} className="vr"></div>
            <div id='change55c' style={{height: height, width: 5, backgroundColor: bgColor55}} className="vr"></div>
            <div id='change56c' style={{height: height, width: 5, backgroundColor: bgColor56}} className="vr"></div>
            <div id='change57c' style={{height: height, width: 5, backgroundColor: bgColor57}} className="vr"></div>
            <div id='change58c' style={{height: height, width: 5, backgroundColor: bgColor58}} className="vr"></div>
            <div id='change59c' style={{height: height, width: 5, backgroundColor: bgColor59}} className="vr"></div>
            <div id='change60c' style={{height: height, width: 5, backgroundColor: bgColor60}} className="vr"></div>
            <div id='change61c' style={{height: height, width: 5, backgroundColor: bgColor61}} className="vr"></div>
            <div id='change62c' style={{height: height, width: 5, backgroundColor: bgColor62}} className="vr"></div>
            <div id='change63c' style={{height: height, width: 5, backgroundColor: bgColor63}} className="vr"></div>
            <div id='change64c' style={{height: height, width: 5, backgroundColor: bgColor64}} className="vr"></div>
            <div id='change65c' style={{height: height, width: 5, backgroundColor: bgColor65}} className="vr"></div>
            <div id='change66c' style={{height: height, width: 5, backgroundColor: bgColor66}} className="vr"></div>
            <div id='change67c' style={{height: height, width: 5, backgroundColor: bgColor67}} className="vr"></div>
            <div id='change68c' style={{height: height, width: 5, backgroundColor: bgColor68}} className="vr"></div>
            <div id='change69c' style={{height: height, width: 5, backgroundColor: bgColor69}} className="vr"></div>
            <div id='change70c' style={{height: height, width: 5, backgroundColor: bgColor70}} className="vr"></div>
            <div id='change71c' style={{height: height, width: 5, backgroundColor: bgColor71}} className="vr"></div>
            <div id='change72c' style={{height: height, width: 5, backgroundColor: bgColor72}} className="vr"></div>
            <div id='change73c' style={{height: height, width: 5, backgroundColor: bgColor73}} className="vr"></div>
            <div id='change74c' style={{height: height, width: 5, backgroundColor: bgColor74}} className="vr"></div>
            <div id='change75c' style={{height: height, width: 5, backgroundColor: bgColor75}} className="vr"></div>
            <div id='change76c' style={{height: height, width: 5, backgroundColor: bgColor76}} className="vr"></div>
            <div id='change77c' style={{height: height, width: 5, backgroundColor: bgColor77}} className="vr"></div>  */}
          </div>
          {/* <div> */}
          {/* <div style={{ fontSize:15, width: 50, color:"#FFFFFF", backgroundColor:"#000000"}}  className="vr"> 3🔥 </div> */}
            {/* <div id='change1b' style={{height: height, width: 5, backgroundColor: bgColor1}} className="vr"></div>
            <div id='change2b' style={{height: height, width: 5, backgroundColor: bgColor2}} className="vr"></div>
            <div id='change3b' style={{height: height, width: 5, backgroundColor: bgColor3}} className="vr"></div>
            <div id='change4b' style={{height: height, width: 5, backgroundColor: bgColor4}} className="vr"></div>
            <div id='change5b' style={{height: height, width: 5, backgroundColor: bgColor5}} className="vr"></div>
            <div id='change6b' style={{height: height, width: 5, backgroundColor: bgColor6}} className="vr"></div>
            <div id='change7b' style={{height: height, width: 5, backgroundColor: bgColor7}} className="vr"></div>
            <div id='change8b' style={{height: height, width: 5, backgroundColor: bgColor8}} className="vr"></div>
            <div id='change9b' style={{height: height, width: 5, backgroundColor: bgColor9}} className="vr"></div>
            <div id='change10b' style={{height: height, width: 5, backgroundColor: bgColor10}} className="vr"></div>
            <div id='change11b' style={{height: height, width: 5, backgroundColor: bgColor11}} className="vr"></div>
            <div id='change12b' style={{height: height, width: 5, backgroundColor: bgColor12}} className="vr"></div>
            <div id='change13b' style={{height: height, width: 5, backgroundColor: bgColor13}} className="vr"></div>
            <div id='change14b' style={{height: height, width: 5, backgroundColor: bgColor14}} className="vr"></div>
            <div id='change15b' style={{height: height, width: 5, backgroundColor: bgColor15}} className="vr"></div>
            <div id='change16b' style={{height: height, width: 5, backgroundColor: bgColor16}} className="vr"></div>
            <div id='change17b' style={{height: height, width: 5, backgroundColor: bgColor17}} className="vr"></div>
            <div id='change18b' style={{height: height, width: 5, backgroundColor: bgColor18}} className="vr"></div>
            <div id='change19b' style={{height: height, width: 5, backgroundColor: bgColor19}} className="vr"></div>
            <div id='change20b' style={{height: height, width: 5, backgroundColor: bgColor20}} className="vr"></div>
            <div id='change21b' style={{height: height, width: 5, backgroundColor: bgColor21}} className="vr"></div>
            <div id='change22b' style={{height: height, width: 5, backgroundColor: bgColor22}} className="vr"></div>
            <div id='change23b' style={{height: height, width: 5, backgroundColor: bgColor23}} className="vr"></div>
            <div id='change24b' style={{height: height, width: 5, backgroundColor: bgColor24}} className="vr"></div>
            <div id='change25b' style={{height: height, width: 5, backgroundColor: bgColor25}} className="vr"></div>
            <div id='change26b' style={{height: height, width: 5, backgroundColor: bgColor26}} className="vr"></div>
            <div id='change27b' style={{height: height, width: 5, backgroundColor: bgColor27}} className="vr"></div>
            <div id='change28b' style={{height: height, width: 5, backgroundColor: bgColor28}} className="vr"></div>
            <div id='change29b' style={{height: height, width: 5, backgroundColor: bgColor29}} className="vr"></div>
            <div id='change30b' style={{height: height, width: 5, backgroundColor: bgColor30}} className="vr"></div>
            <div id='change31b' style={{height: height, width: 5, backgroundColor: bgColor31}} className="vr"></div>
            <div id='change32b' style={{height: height, width: 5, backgroundColor: bgColor32}} className="vr"></div>
            <div id='change33b' style={{height: height, width: 5, backgroundColor: bgColor33}} className="vr"></div>
            <div id='change34b' style={{height: height, width: 5, backgroundColor: bgColor34}} className="vr"></div>
            <div id='change35b' style={{height: height, width: 5, backgroundColor: bgColor35}} className="vr"></div>
            <div id='change36b' style={{height: height, width: 5, backgroundColor: bgColor36}} className="vr"></div>
            <div id='change37b' style={{height: height, width: 5, backgroundColor: bgColor37}} className="vr"></div>
            <div id='change38b' style={{height: height, width: 5, backgroundColor: bgColor38}} className="vr"></div>
            <div id='change39b' style={{height: height, width: 5, backgroundColor: bgColor39}} className="vr"></div>
            <div id='change40b' style={{height: height, width: 5, backgroundColor: bgColor40}} className="vr"></div>
            <div id='change41b' style={{height: height, width: 5, backgroundColor: bgColor41}} className="vr"></div>
            <div id='change42b' style={{height: height, width: 5, backgroundColor: bgColor42}} className="vr"></div>
            <div id='change43b' style={{height: height, width: 5, backgroundColor: bgColor43}} className="vr"></div>
            <div id='change44b' style={{height: height, width: 5, backgroundColor: bgColor44}} className="vr"></div>
            <div id='change45b' style={{height: height, width: 5, backgroundColor: bgColor45}} className="vr"></div>
            <div id='change46b' style={{height: height, width: 5, backgroundColor: bgColor46}} className="vr"></div>
            <div id='change47b' style={{height: height, width: 5, backgroundColor: bgColor47}} className="vr"></div>
            <div id='change48b' style={{height: height, width: 5, backgroundColor: bgColor48}} className="vr"></div>
            <div id='change49b' style={{height: height, width: 5, backgroundColor: bgColor49}} className="vr"></div>
            <div id='change50b' style={{height: height, width: 5, backgroundColor: bgColor50}} className="vr"></div>
            <div id='change51b' style={{height: height, width: 5, backgroundColor: bgColor51}} className="vr"></div>
            <div id='change52b' style={{height: height, width: 5, backgroundColor: bgColor52}} className="vr"></div>
            <div id='change53b' style={{height: height, width: 5, backgroundColor: bgColor53}} className="vr"></div>
            <div id='change54b' style={{height: height, width: 5, backgroundColor: bgColor54}} className="vr"></div>
            <div id='change55b' style={{height: height, width: 5, backgroundColor: bgColor55}} className="vr"></div>
            <div id='change56b' style={{height: height, width: 5, backgroundColor: bgColor56}} className="vr"></div>
            <div id='change57b' style={{height: height, width: 5, backgroundColor: bgColor57}} className="vr"></div>
            <div id='change58b' style={{height: height, width: 5, backgroundColor: bgColor58}} className="vr"></div>
            <div id='change59b' style={{height: height, width: 5, backgroundColor: bgColor59}} className="vr"></div>
            <div id='change60b' style={{height: height, width: 5, backgroundColor: bgColor60}} className="vr"></div>
            <div id='change61b' style={{height: height, width: 5, backgroundColor: bgColor61}} className="vr"></div>
            <div id='change62b' style={{height: height, width: 5, backgroundColor: bgColor62}} className="vr"></div>
            <div id='change63b' style={{height: height, width: 5, backgroundColor: bgColor63}} className="vr"></div>
            <div id='change64b' style={{height: height, width: 5, backgroundColor: bgColor64}} className="vr"></div>
            <div id='change65b' style={{height: height, width: 5, backgroundColor: bgColor65}} className="vr"></div>
            <div id='change66b' style={{height: height, width: 5, backgroundColor: bgColor66}} className="vr"></div>
            <div id='change67b' style={{height: height, width: 5, backgroundColor: bgColor67}} className="vr"></div>
            <div id='change68b' style={{height: height, width: 5, backgroundColor: bgColor68}} className="vr"></div>
            <div id='change69b' style={{height: height, width: 5, backgroundColor: bgColor69}} className="vr"></div>
            <div id='change70b' style={{height: height, width: 5, backgroundColor: bgColor70}} className="vr"></div>
            <div id='change71b' style={{height: height, width: 5, backgroundColor: bgColor71}} className="vr"></div>
            <div id='change72b' style={{height: height, width: 5, backgroundColor: bgColor72}} className="vr"></div>
            <div id='change73b' style={{height: height, width: 5, backgroundColor: bgColor73}} className="vr"></div>
            <div id='change74b' style={{height: height, width: 5, backgroundColor: bgColor74}} className="vr"></div>
            <div id='change75b' style={{height: height, width: 5, backgroundColor: bgColor75}} className="vr"></div>
            <div id='change76b' style={{height: height, width: 5, backgroundColor: bgColor76}} className="vr"></div>
            <div id='change77b' style={{height: height, width: 5, backgroundColor: bgColor77}} className="vr"></div>  */}
          {/* </div> */}
          {/* <div> */}
          {/* <div style={{ fontSize:15, width: 50, color:"#FFFFFF", backgroundColor:"#000000"}}  className="vr"> 2🔥 </div> */}
            {/* <div id='change1a' style={{height: height, width: 5, backgroundColor: bgColor1}} className="vr"></div>
            <div id='change2a' style={{height: height, width: 5, backgroundColor: bgColor2}} className="vr"></div>
            <div id='change3a' style={{height: height, width: 5, backgroundColor: bgColor3}} className="vr"></div>
            <div id='change4a' style={{height: height, width: 5, backgroundColor: bgColor4}} className="vr"></div>
            <div id='change5a' style={{height: height, width: 5, backgroundColor: bgColor5}} className="vr"></div>
            <div id='change6a' style={{height: height, width: 5, backgroundColor: bgColor6}} className="vr"></div>
            <div id='change7a' style={{height: height, width: 5, backgroundColor: bgColor7}} className="vr"></div>
            <div id='change8a' style={{height: height, width: 5, backgroundColor: bgColor8}} className="vr"></div>
            <div id='change9a' style={{height: height, width: 5, backgroundColor: bgColor9}} className="vr"></div>
            <div id='change10a' style={{height: height, width: 5, backgroundColor: bgColor10}} className="vr"></div>
            <div id='change11a' style={{height: height, width: 5, backgroundColor: bgColor11}} className="vr"></div>
            <div id='change12a' style={{height: height, width: 5, backgroundColor: bgColor12}} className="vr"></div>
            <div id='change13a' style={{height: height, width: 5, backgroundColor: bgColor13}} className="vr"></div>
            <div id='change14a' style={{height: height, width: 5, backgroundColor: bgColor14}} className="vr"></div>
            <div id='change15a' style={{height: height, width: 5, backgroundColor: bgColor15}} className="vr"></div>
            <div id='change16a' style={{height: height, width: 5, backgroundColor: bgColor16}} className="vr"></div>
            <div id='change17a' style={{height: height, width: 5, backgroundColor: bgColor17}} className="vr"></div>
            <div id='change18a' style={{height: height, width: 5, backgroundColor: bgColor18}} className="vr"></div>
            <div id='change19a' style={{height: height, width: 5, backgroundColor: bgColor19}} className="vr"></div>
            <div id='change20a' style={{height: height, width: 5, backgroundColor: bgColor20}} className="vr"></div>
            <div id='change21a' style={{height: height, width: 5, backgroundColor: bgColor21}} className="vr"></div>
            <div id='change22a' style={{height: height, width: 5, backgroundColor: bgColor22}} className="vr"></div>
            <div id='change23a' style={{height: height, width: 5, backgroundColor: bgColor23}} className="vr"></div>
            <div id='change24a' style={{height: height, width: 5, backgroundColor: bgColor24}} className="vr"></div>
            <div id='change25a' style={{height: height, width: 5, backgroundColor: bgColor25}} className="vr"></div>
            <div id='change26a' style={{height: height, width: 5, backgroundColor: bgColor26}} className="vr"></div>
            <div id='change27a' style={{height: height, width: 5, backgroundColor: bgColor27}} className="vr"></div>
            <div id='change28a' style={{height: height, width: 5, backgroundColor: bgColor28}} className="vr"></div>
            <div id='change29a' style={{height: height, width: 5, backgroundColor: bgColor29}} className="vr"></div>
            <div id='change30a' style={{height: height, width: 5, backgroundColor: bgColor30}} className="vr"></div>
            <div id='change31a' style={{height: height, width: 5, backgroundColor: bgColor31}} className="vr"></div>
            <div id='change32a' style={{height: height, width: 5, backgroundColor: bgColor32}} className="vr"></div>
            <div id='change33a' style={{height: height, width: 5, backgroundColor: bgColor33}} className="vr"></div>
            <div id='change34a' style={{height: height, width: 5, backgroundColor: bgColor34}} className="vr"></div>
            <div id='change35a' style={{height: height, width: 5, backgroundColor: bgColor35}} className="vr"></div>
            <div id='change36a' style={{height: height, width: 5, backgroundColor: bgColor36}} className="vr"></div>
            <div id='change37a' style={{height: height, width: 5, backgroundColor: bgColor37}} className="vr"></div>
            <div id='change38a' style={{height: height, width: 5, backgroundColor: bgColor38}} className="vr"></div>
            <div id='change39a' style={{height: height, width: 5, backgroundColor: bgColor39}} className="vr"></div>
            <div id='change40a' style={{height: height, width: 5, backgroundColor: bgColor40}} className="vr"></div>
            <div id='change41a' style={{height: height, width: 5, backgroundColor: bgColor41}} className="vr"></div>
            <div id='change42a' style={{height: height, width: 5, backgroundColor: bgColor42}} className="vr"></div>
            <div id='change43a' style={{height: height, width: 5, backgroundColor: bgColor43}} className="vr"></div>
            <div id='change44a' style={{height: height, width: 5, backgroundColor: bgColor44}} className="vr"></div>
            <div id='change45a' style={{height: height, width: 5, backgroundColor: bgColor45}} className="vr"></div>
            <div id='change46a' style={{height: height, width: 5, backgroundColor: bgColor46}} className="vr"></div>
            <div id='change47a' style={{height: height, width: 5, backgroundColor: bgColor47}} className="vr"></div>
            <div id='change48a' style={{height: height, width: 5, backgroundColor: bgColor48}} className="vr"></div>
            <div id='change49a' style={{height: height, width: 5, backgroundColor: bgColor49}} className="vr"></div>
            <div id='change50a' style={{height: height, width: 5, backgroundColor: bgColor50}} className="vr"></div>
            <div id='change51a' style={{height: height, width: 5, backgroundColor: bgColor51}} className="vr"></div>
            <div id='change52a' style={{height: height, width: 5, backgroundColor: bgColor52}} className="vr"></div>
            <div id='change53a' style={{height: height, width: 5, backgroundColor: bgColor53}} className="vr"></div>
            <div id='change54a' style={{height: height, width: 5, backgroundColor: bgColor54}} className="vr"></div>
            <div id='change55a' style={{height: height, width: 5, backgroundColor: bgColor55}} className="vr"></div>
            <div id='change56a' style={{height: height, width: 5, backgroundColor: bgColor56}} className="vr"></div>
            <div id='change57a' style={{height: height, width: 5, backgroundColor: bgColor57}} className="vr"></div>
            <div id='change58a' style={{height: height, width: 5, backgroundColor: bgColor58}} className="vr"></div>
            <div id='change59a' style={{height: height, width: 5, backgroundColor: bgColor59}} className="vr"></div>
            <div id='change60a' style={{height: height, width: 5, backgroundColor: bgColor60}} className="vr"></div>
            <div id='change61a' style={{height: height, width: 5, backgroundColor: bgColor61}} className="vr"></div>
            <div id='change62a' style={{height: height, width: 5, backgroundColor: bgColor62}} className="vr"></div>
            <div id='change63a' style={{height: height, width: 5, backgroundColor: bgColor63}} className="vr"></div>
            <div id='change64a' style={{height: height, width: 5, backgroundColor: bgColor64}} className="vr"></div>
            <div id='change65a' style={{height: height, width: 5, backgroundColor: bgColor65}} className="vr"></div>
            <div id='change66a' style={{height: height, width: 5, backgroundColor: bgColor66}} className="vr"></div>
            <div id='change67a' style={{height: height, width: 5, backgroundColor: bgColor67}} className="vr"></div>
            <div id='change68a' style={{height: height, width: 5, backgroundColor: bgColor68}} className="vr"></div>
            <div id='change69a' style={{height: height, width: 5, backgroundColor: bgColor69}} className="vr"></div>
            <div id='change70a' style={{height: height, width: 5, backgroundColor: bgColor70}} className="vr"></div>
            <div id='change71a' style={{height: height, width: 5, backgroundColor: bgColor71}} className="vr"></div>
            <div id='change72a' style={{height: height, width: 5, backgroundColor: bgColor72}} className="vr"></div>
            <div id='change73a' style={{height: height, width: 5, backgroundColor: bgColor73}} className="vr"></div>
            <div id='change74a' style={{height: height, width: 5, backgroundColor: bgColor74}} className="vr"></div>
            <div id='change75a' style={{height: height, width: 5, backgroundColor: bgColor75}} className="vr"></div>
            <div id='change76a' style={{height: height, width: 5, backgroundColor: bgColor76}} className="vr"></div>
            <div id='change77a' style={{height: height, width: 5, backgroundColor: bgColor77}} className="vr"></div> 
          </div> */}
          <div>
          {/* <div style={{ fontSize:15, width: 50, color:"#FFFFFF", backgroundColor:"#000000"}}  className="vr"> 🔥</div> */}
            <div id='change1' style={{height: height1, width: 5, backgroundColor: bgColor1}} className="vr"></div>
            <div id='change2' style={{height: height1, width: 5, backgroundColor: bgColor2}} className="vr"></div>
            <div id='change3' style={{height: height1, width: 5, backgroundColor: bgColor3}} className="vr"></div>
            <div id='change4' style={{height: height1, width: 5, backgroundColor: bgColor4}} className="vr"></div>
            <div id='change5' style={{height: height1, width: 5, backgroundColor: bgColor5}} className="vr"></div>
            <div id='change6' style={{height: height1, width: 5, backgroundColor: bgColor6}} className="vr"></div>
            <div id='change7' style={{height: height1, width: 5, backgroundColor: bgColor7}} className="vr"></div>
            <div id='change8' style={{height: height1, width: 5, backgroundColor: bgColor8}} className="vr"></div>
            <div id='change9' style={{height: height1, width: 5, backgroundColor: bgColor9}} className="vr"></div>
            <div id='change10' style={{height: height1, width: 5, backgroundColor: bgColor10}} className="vr"></div>
            <div id='change11' style={{height: height1, width: 5, backgroundColor: bgColor11}} className="vr"></div>
            <div id='change12' style={{height: height1, width: 5, backgroundColor: bgColor12}} className="vr"></div>
            <div id='change13' style={{height: height1, width: 5, backgroundColor: bgColor13}} className="vr"></div>
            <div id='change14' style={{height: height1, width: 5, backgroundColor: bgColor14}} className="vr"></div>
            <div id='change15' style={{height: height1, width: 5, backgroundColor: bgColor15}} className="vr"></div>
            <div id='change16' style={{height: height1, width: 5, backgroundColor: bgColor16}} className="vr"></div>
            <div id='change17' style={{height: height1, width: 5, backgroundColor: bgColor17}} className="vr"></div>
            <div id='change18' style={{height: height1, width: 5, backgroundColor: bgColor18}} className="vr"></div>
            <div id='change19' style={{height: height1, width: 5, backgroundColor: bgColor19}} className="vr"></div>
            <div id='change20' style={{height: height1, width: 5, backgroundColor: bgColor20}} className="vr"></div>
            <div id='change21' style={{height: height1, width: 5, backgroundColor: bgColor21}} className="vr"></div>
            <div id='change22' style={{height: height1, width: 5, backgroundColor: bgColor22}} className="vr"></div>
            <div id='change23' style={{height: height1, width: 5, backgroundColor: bgColor23}} className="vr"></div>
            <div id='change24' style={{height: height1, width: 5, backgroundColor: bgColor24}} className="vr"></div>
            <div id='change25' style={{height: height1, width: 5, backgroundColor: bgColor25}} className="vr"></div>
            <div id='change26' style={{height: height1, width: 5, backgroundColor: bgColor26}} className="vr"></div>
            <div id='change27' style={{height: height1, width: 5, backgroundColor: bgColor27}} className="vr"></div>
            <div id='change28' style={{height: height1, width: 5, backgroundColor: bgColor28}} className="vr"></div>
            <div id='change29' style={{height: height1, width: 5, backgroundColor: bgColor29}} className="vr"></div>
            <div id='change30' style={{height: height1, width: 5, backgroundColor: bgColor30}} className="vr"></div>
            <div id='change31' style={{height: height1, width: 5, backgroundColor: bgColor31}} className="vr"></div>
            <div id='change32' style={{height: height1, width: 5, backgroundColor: bgColor32}} className="vr"></div>
            <div id='change33' style={{height: height1, width: 5, backgroundColor: bgColor33}} className="vr"></div>
            <div id='change34' style={{height: height1, width: 5, backgroundColor: bgColor34}} className="vr"></div>
            <div id='change35' style={{height: height1, width: 5, backgroundColor: bgColor35}} className="vr"></div>
            <div id='change36' style={{height: height1, width: 5, backgroundColor: bgColor36}} className="vr"></div>
            <div id='change37' style={{height: height1, width: 5, backgroundColor: bgColor37}} className="vr"></div>
            <div id='change38' style={{height: height1, width: 5, backgroundColor: bgColor38}} className="vr"></div>
            <div id='change39' style={{height: height1, width: 5, backgroundColor: bgColor39}} className="vr"></div>
            <div id='change40' style={{height: height1, width: 5, backgroundColor: bgColor40}} className="vr"></div>
            <div id='change41' style={{height: height1, width: 5, backgroundColor: bgColor41}} className="vr"></div>
            <div id='change42' style={{height: height1, width: 5, backgroundColor: bgColor42}} className="vr"></div>
            <div id='change43' style={{height: height1, width: 5, backgroundColor: bgColor43}} className="vr"></div>
            <div id='change44' style={{height: height1, width: 5, backgroundColor: bgColor44}} className="vr"></div>
            <div id='change45' style={{height: height1, width: 5, backgroundColor: bgColor45}} className="vr"></div>
            <div id='change46' style={{height: height1, width: 5, backgroundColor: bgColor46}} className="vr"></div>
            <div id='change47' style={{height: height1, width: 5, backgroundColor: bgColor47}} className="vr"></div>
            <div id='change48' style={{height: height1, width: 5, backgroundColor: bgColor48}} className="vr"></div>
            <div id='change49' style={{height: height1, width: 5, backgroundColor: bgColor49}} className="vr"></div>
            <div id='change50' style={{height: height1, width: 5, backgroundColor: bgColor50}} className="vr"></div>
            <div id='change51' style={{height: height1, width: 5, backgroundColor: bgColor51}} className="vr"></div>
            <div id='change52' style={{height: height1, width: 5, backgroundColor: bgColor52}} className="vr"></div>
            <div id='change53' style={{height: height1, width: 5, backgroundColor: bgColor53}} className="vr"></div>
            <div id='change54' style={{height: height1, width: 5, backgroundColor: bgColor54}} className="vr"></div>
            <div id='change55' style={{height: height1, width: 5, backgroundColor: bgColor55}} className="vr"></div>
            <div id='change56' style={{height: height1, width: 5, backgroundColor: bgColor56}} className="vr"></div>
            <div id='change57' style={{height: height1, width: 5, backgroundColor: bgColor57}} className="vr"></div>
            <div id='change58' style={{height: height1, width: 5, backgroundColor: bgColor58}} className="vr"></div>
            <div id='change59' style={{height: height1, width: 5, backgroundColor: bgColor59}} className="vr"></div>
            <div id='change60' style={{height: height1, width: 5, backgroundColor: bgColor60}} className="vr"></div>
            <div id='change61' style={{height: height1, width: 5, backgroundColor: bgColor61}} className="vr"></div>
            <div id='change62' style={{height: height1, width: 5, backgroundColor: bgColor62}} className="vr"></div>
            <div id='change63' style={{height: height1, width: 5, backgroundColor: bgColor63}} className="vr"></div>
            <div id='change64' style={{height: height1, width: 5, backgroundColor: bgColor64}} className="vr"></div>
            <div id='change65' style={{height: height1, width: 5, backgroundColor: bgColor65}} className="vr"></div>
            <div id='change66' style={{height: height1, width: 5, backgroundColor: bgColor66}} className="vr"></div>
            <div id='change67' style={{height: height1, width: 5, backgroundColor: bgColor67}} className="vr"></div>
            <div id='change68' style={{height: height1, width: 5, backgroundColor: bgColor68}} className="vr"></div>
            <div id='change69' style={{height: height1, width: 5, backgroundColor: bgColor69}} className="vr"></div>
            <div id='change70' style={{height: height1, width: 5, backgroundColor: bgColor70}} className="vr"></div>
            <div id='change71' style={{height: height1, width: 5, backgroundColor: bgColor71}} className="vr"></div>
            <div id='change72' style={{height: height1, width: 5, backgroundColor: bgColor72}} className="vr"></div>
            <div id='change73' style={{height: height1, width: 5, backgroundColor: bgColor73}} className="vr"></div>
            <div id='change74' style={{height: height1, width: 5, backgroundColor: bgColor74}} className="vr"></div>
            <div id='change75' style={{height: height1, width: 5, backgroundColor: bgColor75}} className="vr"></div>
            <div id='change76' style={{height: height1, width: 5, backgroundColor: bgColor76}} className="vr"></div>
            <div id='change77' style={{height: height1, width: 5, backgroundColor: bgColor77}} className="vr"></div> 
          </div>
          <div style={{textAlign: "center"}}><b class="mb-1"> </b>  <small class="text-muted"> 00:00:00 -  {time} </small></div>
          </div>
       
    <div> 
      <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 50, height: 50}} variant="outline-light" onClick={times1}>
      x1
      </Button>

      <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 50, height: 50}} variant="outline-light" onClick={times2}>
      x2
      </Button>

      <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 50, height: 50}} variant="outline-light" onClick={times3}>
      x3
      </Button>

      <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 50, height: 50}} variant="outline-light" onClick={times4}>
      x4
      </Button>

      <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 50, height: 50}} variant="outline-light" onClick={times5}>
      x5
      </Button>
    </div>
  


    {show5 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#FF8700"}} variant="outline-dark" onClick={changeColor5}>
    😍
    </Button> }
    {show1 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#FFFF00"}} variant="outline-dark" onClick={changeColor1}>
    😂
    {/* <Badge style={{color:'white', height: 18}} bg="dark">3</Badge> */}
    {/* <Stack direction="horizontal" gap={1}>
      <div> <Badge bg="transparent">  </Badge> </div>
      <div> <Badge style={{color:'#00008B', height: 18}} bg="transparent">3</Badge> </div>
    </Stack>
    <Stack direction="horizontal" gap={1}>
      <div>😂</div>
    </Stack> */}
    </Button> 
    }
    {/* {show15 &&
      <Button style={{ margin: 5, fontSize:25,  marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#FFC400"}} variant="outline-dark" onClick={changeColor15}>
      🤣
      </Button> 
    } */}
    {show3 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#7CFC00"}} variant="outline-dark" onClick={changeColor3}>
    👍
    {/* <Badge style={{color:'white', height: 18}} bg="dark">6</Badge> */}
    {/* <Stack direction="horizontal" gap={1}>
      <div> <Badge bg="transparent">  </Badge> </div>
      <div> <Badge style={{color:'#00008B', height: 18}} bg="transparent">6</Badge> </div>
    </Stack>
    <Stack direction="horizontal" gap={1}>
      <div>👍</div>
    </Stack> */}
    </Button> }
    {show13 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#6F861A"}} variant="outline-dark" onClick={changeColor13}>
    👎
    </Button> }
    {show4 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#FF00D3"}} variant="outline-dark" onClick={changeColor4}>
    🤯
    </Button> }
    {show2 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#00D1FF"}} variant="outline-dark" onClick={changeColor2}>
    🤩
    </Button> }
    {show14 &&
      <Button style={{ margin: 5, fontSize:25,  marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#E47070"}} variant="outline-dark" onClick={changeColor14}>
      🔞
      </Button> 
    }
    {show6 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#A45BBB"}} variant="outline-dark" onClick={changeColor6}>
    😮
    </Button> }
    {show7 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#778899"}} variant="outline-dark" onClick={changeColor7}>
    🥱
    </Button> }
   
    {show10 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#55AAAA"}} variant="outline-dark" onClick={changeColor10}>
    😭
    </Button> }
    {show8 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#8200FF"}} variant="outline-dark" onClick={changeColor8}>
    😱
    </Button> }
    {show11 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#B22222"}} variant="outline-dark" onClick={changeColor11}>
    ☹️
    </Button> }
    {show12 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#FF0000"}} variant="outline-dark" onClick={changeColor12}>
    😡
    </Button> 
    }
    {show9 &&
    <Button style={{ margin: 5,  fontSize:25, marginTop: 30, borderRadius:20, width: 70, height: 70, backgroundColor:"#FFFFFF"}} variant="outline-dark" onClick={changeColor9}>
    ✍️
    </Button> }

    <div>

      <Button id="pauseButton" style={{marginTop: 30, borderRadius:25, width: 120, height: 50}} onClick={pause} variant="outline-light">
         {pause_var}
      </Button>

    </div>
      
  </div>
          }
          


          {showCarosel &&
          <Carousel indicators={false} interval={null}>
          <Carousel.Item >
              {/* <div class="centered"> */}
            <div id='change1' style={{height: 50, width: 1, backgroundColor: 'transparent'}} className="vr">  </div>
            <div style={{ textAlign: "center"}}><b class="mb-1"> </b>  <small class="text-muted">How many scenes will I get these vibes from?: <h5> {title} </h5> </small></div>
                {showChart &&
              <Bar style={{margin:20, display: 'flex',  justifyContent:'center', alignItems:'center', height: '200vh'}} data={data} options={options}> </Bar>
              }
              {/* <div id='change1' style={{height: 100, width: 1, backgroundColor: 'transparent'}} className="vr">  </div> */}
              {/* </div> */}

              {/* <div style={{textColor: '#DAF7A6', fontSize:13, marginTop: 50, marginLeft:20, marginRight:20}}><b class="mb-1"> </b>  <small class="text-muted"> <i>What Emotions will I feel, from start till end? 
              </i></small></div> */}

          </Carousel.Item>

          <Carousel.Item >
              {/* <div class="centered"> */}
            <div id='change1' style={{height: 50, width: 1, backgroundColor: 'transparent'}} className="vr">  </div>
            <div style={{ textAlign: "center"}}><b class="mb-1"> </b>  <small class="text-muted">How Engaging is it from Start till End?: <h5>{title}</h5> </small></div>
                {showChart &&
              <Line style={{margin:20, display: 'flex',  justifyContent:'center', alignItems:'center', height: '200vh'}} data={data_line} options={options_line}> </Line>
              }

              {/* <div style={{textColor: '#DAF7A6', fontSize:13, marginTop: 50, marginLeft:20, marginRight:20}}><b class="mb-1"> </b>  <small class="text-muted"> <i>How engaged will I be, from start till end?
              </i></small></div> */}

          </Carousel.Item>

          <Carousel.Item>
            <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: '70vh'}} >
              <Image style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70}}  src="./Dizzy.jpg" rounded/>
              <div style={{ marginTop: 30, marginLeft:20, marginRight:20}}><b class="mb-1"> </b>  <small class="text-muted"> SpoilerGraph's Score: <h4> {mean} </h4>  </small></div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
          <div>
          <div style={{marginTop:40, marginLeft:20, textAlign: "left"}}><b class="mb-1"> </b>  <small class="text-muted">  </small></div>
          <div style={{marginLeft:20, textAlign: "center"}}><b class="mb-1"> </b>  <small class="text-muted"> Vibes Map: <h4>{title}</h4></small></div>
        <div style={{marginTop:10}} >
            <div id='change1' style={{height: 100, width: 5, backgroundColor: bgColor1}} className="vr"></div>
            <div id='change2' style={{height: 100, width: 5, backgroundColor: bgColor2}} className="vr"></div>
            <div id='change3' style={{height: 100, width: 5, backgroundColor: bgColor3}} className="vr"></div>
            <div id='change4' style={{height: 100, width: 5, backgroundColor: bgColor4}} className="vr"></div>
            <div id='change5' style={{height: 100, width: 5, backgroundColor: bgColor5}} className="vr"></div>
            <div id='change6' style={{height: 100, width: 5, backgroundColor: bgColor6}} className="vr"></div>
            <div id='change7' style={{height: 100, width: 5, backgroundColor: bgColor7}} className="vr"></div>
            <div id='change8' style={{height: 100, width: 5, backgroundColor: bgColor8}} className="vr"></div>
            <div id='change9' style={{height: 100, width: 5, backgroundColor: bgColor9}} className="vr"></div>
            <div id='change10' style={{height: 100, width: 5, backgroundColor: bgColor10}} className="vr"></div>
            <div id='change11' style={{height: 100, width: 5, backgroundColor: bgColor11}} className="vr"></div>
            <div id='change12' style={{height: 100, width: 5, backgroundColor: bgColor12}} className="vr"></div>
            <div id='change13' style={{height: 100, width: 5, backgroundColor: bgColor13}} className="vr"></div>
            <div id='change14' style={{height: 100, width: 5, backgroundColor: bgColor14}} className="vr"></div>
            <div id='change15' style={{height: 100, width: 5, backgroundColor: bgColor15}} className="vr"></div>
            <div id='change16' style={{height: 100, width: 5, backgroundColor: bgColor16}} className="vr"></div>
            <div id='change17' style={{height: 100, width: 5, backgroundColor: bgColor17}} className="vr"></div>
            <div id='change18' style={{height: 100, width: 5, backgroundColor: bgColor18}} className="vr"></div>
            <div id='change19' style={{height: 100, width: 5, backgroundColor: bgColor19}} className="vr"></div>
            <div id='change20' style={{height: 100, width: 5, backgroundColor: bgColor20}} className="vr"></div>
            <div id='change21' style={{height: 100, width: 5, backgroundColor: bgColor21}} className="vr"></div>
            <div id='change22' style={{height: 100, width: 5, backgroundColor: bgColor22}} className="vr"></div>
            <div id='change23' style={{height: 100, width: 5, backgroundColor: bgColor23}} className="vr"></div>
            <div id='change24' style={{height: 100, width: 5, backgroundColor: bgColor24}} className="vr"></div>
            <div id='change25' style={{height: 100, width: 5, backgroundColor: bgColor25}} className="vr"></div>
            <div id='change26' style={{height: 100, width: 5, backgroundColor: bgColor26}} className="vr"></div>
            <div id='change27' style={{height: 100, width: 5, backgroundColor: bgColor27}} className="vr"></div>
            <div id='change28' style={{height: 100, width: 5, backgroundColor: bgColor28}} className="vr"></div>
            <div id='change29' style={{height: 100, width: 5, backgroundColor: bgColor29}} className="vr"></div>
            <div id='change30' style={{height: 100, width: 5, backgroundColor: bgColor30}} className="vr"></div>
            <div id='change31' style={{height: 100, width: 5, backgroundColor: bgColor31}} className="vr"></div>
            <div id='change32' style={{height: 100, width: 5, backgroundColor: bgColor32}} className="vr"></div>
            <div id='change33' style={{height: 100, width: 5, backgroundColor: bgColor33}} className="vr"></div>
            <div id='change34' style={{height: 100, width: 5, backgroundColor: bgColor34}} className="vr"></div>
            <div id='change35' style={{height: 100, width: 5, backgroundColor: bgColor35}} className="vr"></div>
            <div id='change36' style={{height: 100, width: 5, backgroundColor: bgColor36}} className="vr"></div>
            <div id='change37' style={{height: 100, width: 5, backgroundColor: bgColor37}} className="vr"></div>
            <div id='change38' style={{height: 100, width: 5, backgroundColor: bgColor38}} className="vr"></div>
            <div id='change39' style={{height: 100, width: 5, backgroundColor: bgColor39}} className="vr"></div>
            <div id='change40' style={{height: 100, width: 5, backgroundColor: bgColor40}} className="vr"></div>
            <div id='change41' style={{height: 100, width: 5, backgroundColor: bgColor41}} className="vr"></div>
            <div id='change42' style={{height: 100, width: 5, backgroundColor: bgColor42}} className="vr"></div>
            <div id='change43' style={{height: 100, width: 5, backgroundColor: bgColor43}} className="vr"></div>
            <div id='change44' style={{height: 100, width: 5, backgroundColor: bgColor44}} className="vr"></div>
            <div id='change45' style={{height: 100, width: 5, backgroundColor: bgColor45}} className="vr"></div>
            <div id='change46' style={{height: 100, width: 5, backgroundColor: bgColor46}} className="vr"></div>
            <div id='change47' style={{height: 100, width: 5, backgroundColor: bgColor47}} className="vr"></div>
            <div id='change48' style={{height: 100, width: 5, backgroundColor: bgColor48}} className="vr"></div>
            <div id='change49' style={{height: 100, width: 5, backgroundColor: bgColor49}} className="vr"></div>
            <div id='change50' style={{height: 100, width: 5, backgroundColor: bgColor50}} className="vr"></div>
            <div id='change51' style={{height: 100, width: 5, backgroundColor: bgColor51}} className="vr"></div>
            <div id='change52' style={{height: 100, width: 5, backgroundColor: bgColor52}} className="vr"></div>
            <div id='change53' style={{height: 100, width: 5, backgroundColor: bgColor53}} className="vr"></div>
            <div id='change54' style={{height: 100, width: 5, backgroundColor: bgColor54}} className="vr"></div>
            <div id='change55' style={{height: 100, width: 5, backgroundColor: bgColor55}} className="vr"></div>
            <div id='change56' style={{height: 100, width: 5, backgroundColor: bgColor56}} className="vr"></div>
            <div id='change57' style={{height: 100, width: 5, backgroundColor: bgColor57}} className="vr"></div>
            <div id='change58' style={{height: 100, width: 5, backgroundColor: bgColor58}} className="vr"></div>
            <div id='change59' style={{height: 100, width: 5, backgroundColor: bgColor59}} className="vr"></div>
            <div id='change60' style={{height: 100, width: 5, backgroundColor: bgColor60}} className="vr"></div>
            <div id='change61' style={{height: 100, width: 5, backgroundColor: bgColor61}} className="vr"></div>
            <div id='change62' style={{height: 100, width: 5, backgroundColor: bgColor62}} className="vr"></div>
            <div id='change63' style={{height: 100, width: 5, backgroundColor: bgColor63}} className="vr"></div>
            <div id='change64' style={{height: 100, width: 5, backgroundColor: bgColor64}} className="vr"></div>
            <div id='change65' style={{height: 100, width: 5, backgroundColor: bgColor65}} className="vr"></div>
            <div id='change66' style={{height: 100, width: 5, backgroundColor: bgColor66}} className="vr"></div>
            <div id='change67' style={{height: 100, width: 5, backgroundColor: bgColor67}} className="vr"></div>
            <div id='change68' style={{height: 100, width: 5, backgroundColor: bgColor68}} className="vr"></div>
            <div id='change69' style={{height: 100, width: 5, backgroundColor: bgColor69}} className="vr"></div>
            <div id='change70' style={{height: 100, width: 5, backgroundColor: bgColor70}} className="vr"></div>
            <div id='change71' style={{height: 100, width: 5, backgroundColor: bgColor71}} className="vr"></div>
            <div id='change72' style={{height: 100, width: 5, backgroundColor: bgColor72}} className="vr"></div>
            <div id='change73' style={{height: 100, width: 5, backgroundColor: bgColor73}} className="vr"></div>
            <div id='change74' style={{height: 100, width: 5, backgroundColor: bgColor74}} className="vr"></div>
            <div id='change75' style={{height: 100, width: 5, backgroundColor: bgColor75}} className="vr"></div>
            <div id='change76' style={{height: 100, width: 5, backgroundColor: bgColor76}} className="vr"></div>
            <div id='change77' style={{height: 100, width: 5, backgroundColor: bgColor77}} className="vr"></div>
            {/* <div id='change78' style={{height: 100, width: 5, backgroundColor: bgColor78}} className="vr"></div>
            <div id='change79' style={{height: 100, width: 5, backgroundColor: bgColor79}} className="vr"></div>
            <div id='change80' style={{height: 100, width: 5, backgroundColor: bgColor80}} className="vr"></div> */}
          </div>
          <div style={{textAlign: "center"}}><b class="mb-1"> </b>  <small class="text-muted"> 00:00:00  -  {time} </small></div>
          {/* <div style={{marginRight:20, textAlign: "right"}}><b class="mb-1"> </b>  <small class="text-muted"> {time} </small></div> */}
          </div>

          <div style={{textColor: '#DAF7A6', fontSize:13, marginTop: 30, marginLeft:20, marginRight:20}}><b class="mb-1"> </b>  <small class="text-muted"> <i> From left to right; Each tall colored bar show the exact Vibes you will feel watching this movie from beginning till end. </i></small></div>

    {show5 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#FF8700"}} variant="outline-dark" onClick={'changeColor5'}>
    😍
    {/* <Badge style={{color:'white', height: 18}} bg="dark">1</Badge> */}

    {/* <Stack direction="horizontal" gap={1}>
      <div> <Badge bg="transparent">  </Badge> </div>
      <div> <Badge style={{color:'#00008B', height: 18}} bg="transparent">1</Badge> </div>
    </Stack>
    <Stack direction="horizontal" gap={1}>
      <div>😍</div>
    </Stack> */}
    </Button> }
    {show1 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#FFFF00"}} variant="outline-dark" onClick={'changeColor1'}>
    😂
    {/* <Badge style={{color:'white', height: 18}} bg="dark">3</Badge> */}
    {/* <Stack direction="horizontal" gap={1}>
      <div> <Badge bg="transparent">  </Badge> </div>
      <div> <Badge style={{color:'#00008B', height: 18}} bg="transparent">3</Badge> </div>
    </Stack>
    <Stack direction="horizontal" gap={1}>
      <div>😂</div>
    </Stack> */}
    </Button> 
    }
    {show3 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#7CFC00"}} variant="outline-dark" onClick={'changeColor3'}>
    👍
    {/* <Badge style={{color:'white', height: 18}} bg="dark">6</Badge> */}
    {/* <Stack direction="horizontal" gap={1}>
      <div> <Badge bg="transparent">  </Badge> </div>
      <div> <Badge style={{color:'#00008B', height: 18}} bg="transparent">6</Badge> </div>
    </Stack>
    <Stack direction="horizontal" gap={1}>
      <div>👍</div>
    </Stack> */}
    </Button> }
    {show13 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#6F861A"}} variant="outline-dark" onClick={'changeColor13'}>
    👎
    </Button> }
    {show4 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#FF00D3"}} variant="outline-dark" onClick={'changeColor4'}>
    🤯
    </Button> }
    {show2 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#00D1FF"}} variant="outline-dark" onClick={'changeColor2'}>
    🤩
    </Button> }
    {show14 &&
      <Button style={{ margin: 5, fontSize:25,  marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#E47070"}} variant="outline-dark" onClick={'changeColor14'}>
      🔞
      </Button> 
    }
    {show6 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#A45BBB"}} variant="outline-dark" onClick={'changeColor6'}>
    😮
    </Button> }
    {show7 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#778899"}} variant="outline-dark" onClick={'changeColor7'}>
    🥱
    </Button> }
   
    {show10 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#55AAAA"}} variant="outline-dark" onClick={'changeColor10'}>
    😭
    </Button> }
    {show8 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#8200FF"}} variant="outline-dark" onClick={'changeColor8'}>
    😱
    </Button> }
    {show11 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#B22222"}} variant="outline-dark" onClick={'changeColor11'}>
    ☹️
    </Button> }
    {show12 &&
    <Button style={{ margin: 5, fontSize:25,  marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#FF0000"}} variant="outline-dark" onClick={'changeColor12'}>
    😡
    </Button> 
    }
    {show9 &&
    <Button style={{ margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70, backgroundColor:"#FFFFFF"}} variant="outline-dark" onClick={'changeColor9'}>
    ✍️
    </Button> }



  </Carousel.Item>

      {/* <Carousel.Item>
        <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: '70vh'}}>
          <Image style={{margin: 5, fontSize:25, marginTop: 50, borderRadius:20, width: 70, height: 70}}  src="./popcorn.png" rounded/>
          <div style={{ marginTop: 30, marginLeft:20, marginRight:20}}><b class="mb-1"> </b>  <small class="text-muted"> <h4> 65% </h4> of RottenTomatoe's Audience says it was enjoyable!   </small></div>
        </div> 
      </Carousel.Item> */}

  </Carousel>

          }


    {/* <Form>
              <FloatingLabel style={{  borderRadius: 0}}
                onChange={handleSearchTextChange}
                controlId="floatingInput"
                label=" title? (optional)"
                value={searchText}
                className="mb-3"
              >
                <Form.Control type="name" placeholder="Art name?" />
          </FloatingLabel>

            <Form.Select style={{marginBottom: 5, color: '#0020BC'}}>
            {filteredHints.map((hint) => (
              <option>{hint.key}, ({hint.startPoint})</option>
            ))}
            
            </Form.Select>

          <Button style={{ marginLeft: '75%', margin: 5, borderRadius:25, width: 120, height: 50}} onClick={'startPainting'} variant="outline-dark">
            Search
          </Button>
    </Form> */}


    <Modal show={show} onHide={'handleClose'}>
        <Modal.Header>
         {/* <Modal.Title>Join a Mood Painting or Start Yours?</Modal.Title>  */}
          {/* <Modal.Title> How long would you like to paint for?</Modal.Title> */}
          <Modal.Title> Don't Regret! Check Spoiler First... </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form>
          {/* <FloatingLabel style={{ margin: 10, borderRadius: 0}}
                onChange={(e) => setKey(e.target.value)}
                controlId="floatingInput"
                label="Input Keyword?"
                className="mb-3"
              >
                <Form.Control type="name" placeholder="key?" />
                <Form.Text className="text-muted">
                Enter keyword to join art room? e.g: mfm
                </Form.Text>
          </FloatingLabel>  

          <FloatingLabel style={{ margin: 10, borderRadius: 0}}
                onChange={(e) => setKey(e.target.value)}
                controlId="floatingInput"
                label="Input Art Name?"
                className="mb-3"
              >
                <Form.Control type="name" placeholder="Art name?" />
                <Form.Text className="text-muted">
           What your Art name? e.g: Jack Butcher
          </Form.Text>
          </FloatingLabel>  


          <div style={{alignItems: 'right'}}>
            <Button style={{ alignItems: 'right', margin: 5, borderRadius:25, width: 120, height: 50}} onClick={findGroups} variant="outline-dark">
              Join
            </Button>
          </div>
          
         
          

          <div style={{ textAlign: 'center', marginTop: 0, marginBottom: 0}}>
              OR
          </div> 
          <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 30}}>
              Start yours:
          </div>   */}


          </Form>
          <Form>
          
          <FloatingLabel style={{ margin: 10, borderRadius: 0}}
                // onChange={getTitle}
                controlId="floatingInput"
                label="title of movie?"
                className="mb-3"
              >
            <Form.Control type='text' placeholder="Art name?" 
            className='searchBar'
            onChange={handleSearchTextChange}
            value={searchText}
            />
              <Form.Text className="text-muted">
              which movie would you like to see?
              </Form.Text>

              <Form style={{ borderColor: 'red'}}>
              <Form.Check 
                type="switch"
                id="custom-switch"
                reverse='true'
                label='live view?'
                onChange={liveSwitch}
                // label="Check this switch"
              />
              </Form>

          </FloatingLabel> 
            <div className='searchBack'
            onClick={getSelectedHint}
            >
            {filteredHints.map((hint, index) => (
              <a href='#' key={index} onClick={()=>{setID(hint)}}>
                <div value={hint.id} className="searchEntry">
                  {hint.title} ({hint.timeLengthFull}) by {hint.userName}
                </div>
              </a>
                    ))}
            </div>

           

          <Button style={{ marginLeft: '75%', margin: 5, borderRadius:25, width: 120, height: 50}} onClick={searchHints} variant="outline-dark">
            Search
          </Button>


          
          <p><center><b>OR</b></center></p>
          <p></p>

        </Form>

        <Accordion style={{ marginBottom: 30, marginTop: 30,borderRadius: 0}}>
      <Accordion.Item eventKey="0">
        <Accordion.Header><center>Click here to review a movie yourself?</center></Accordion.Header>
        <Accordion.Body>

          <Form>
          <FloatingLabel style={{ margin: 10, borderRadius: 0}}
                onChange={(e) => setTime(e.target.value)}
                controlId="floatingInput"
                label="e.g: 00:01:00"
                className="mb-3"
              >
                <Form.Control type="phonenumber" placeholder="hh:mm:ss" />
                <Form.Text className="text-muted">
           hh:mm:ss format only.
          </Form.Text>
          </FloatingLabel> 

          <FloatingLabel style={{ margin: 10, borderRadius: 0}}
                onChange={getTitle}
                controlId="floatingInput"
                label="full video title?"
                className="mb-3"
              >
                <Form.Control type="name" placeholder="Art name?" />
                <Form.Text className="text-muted">
           what's the full title of the movie?
          </Form.Text>
          </FloatingLabel> 

          <FloatingLabel style={{ margin: 10, borderRadius: 0}}
                onChange={getUserName}
                controlId="floatingInput"
                label="username?"
                className="mb-3"
              >
                <Form.Control type="name" placeholder="Art name?" />
                <Form.Text className="text-muted">
           your username?
          </Form.Text>
          </FloatingLabel>


          <Button style={{ marginLeft: '75%', margin: 5, borderRadius:25, width: 120, height: 50}} onClick={startPainting} variant="outline-dark">
            Start
          </Button>
          </Form>

          </Accordion.Body>
        </Accordion.Item>
        </Accordion>
           

        </Modal.Body>
      {/* <Modal.Footer> 
      </Modal.Footer>  */}
      </Modal>
    
    </div>
  );
}


export default App;
